<div class="max-w-80 rounded-lg">
  <div class="flex justify-between">
    <div>
      <mat-icon class="mr-3 text-green-600">check_circle</mat-icon>
    </div>
    <div>
      <mat-icon
        class="cursor-pointer text-gray-500 hover:text-gray-700"
        (click)="dismissSnackBar()"
        >close
      </mat-icon>
    </div>
  </div>
  <div class="flex">
    <div class="grow">
      @if (data.title) {
        <h4 class="font-semibold">{{ data.title }}</h4>
      }
      <p>{{ data.message }}</p>
    </div>
  </div>
</div>
