import { Component, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IMessage } from '../success-snack-bar/success-snack-bar.component';

@Component({
  selector: 'app-error-snack-bar',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './error-snack-bar.component.html',
  styleUrl: './error-snack-bar.component.css',
})
export class ErrorSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IMessage,
    private readonly snackBarRef: MatSnackBarRef<ErrorSnackBarComponent>,
  ) {}

  dismissSnackBar() {
    this.snackBarRef.dismiss();
  }
}
