import { inject } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseApiRepository } from './base-api-repository';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { CurrentUserState } from '../store/current-user/current-user.state';

export class PortalApiRepository extends BaseApiRepository {
  protected readonly store = inject(Store);

  constructor() {
    super();
  }

  private addHeaders(headers: HttpHeaders = new HttpHeaders()): HttpHeaders | undefined {
    const tenantId = this.store.selectSnapshot(CurrentUserState.tenantId);
    if (!tenantId) {
      return undefined;
    }
    headers = headers.set('X-TENANT-ID', tenantId);
    return headers;
  }

  download(path: string, queryParams?: HttpParams) {
    const headers = this.addHeaders();
    return super.sendGetBlobRequest(path, queryParams, headers);
  }

  get<T>(path: string, queryParams?: HttpParams): Observable<T> {
    const headers = this.addHeaders();
    return super.sendGetRequest<T>(path, queryParams, headers);
  }

  post<P, T>(path: string, payload?: P, queryParams?: HttpParams): Observable<T> {
    const headers = this.addHeaders();
    return super.sendPostRequest<P, T>(path, payload, queryParams, headers);
  }

  patch<P, T>(path: string, payload?: P, queryParams?: HttpParams): Observable<T> {
    const headers = this.addHeaders();
    return super.sendPatchRequest<P, T>(path, payload, queryParams, headers);
  }
}
