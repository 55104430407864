import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface IMessage {
  title?: string;
  message: string;
}

@Component({
  selector: 'app-success-snack-bar',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './success-snack-bar.component.html',
  styleUrl: './success-snack-bar.component.css',
})
export class SuccessSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IMessage,
    public snackBarRef: MatSnackBarRef<SuccessSnackBarComponent>,
  ) {}

  dismissSnackBar() {
    this.snackBarRef.dismiss();
  }
}
