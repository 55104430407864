import { Component } from '@angular/core';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [LottieComponent],
  templateUrl: './loading.component.html',
})
export class LoadingComponent {
  options: AnimationOptions = {
    path: '/assets/lottie/loading-spinner.json',
  };
}
