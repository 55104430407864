import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LanguageService } from './language.service';
import { SuccessSnackBarComponent } from '../../shared/components/success-snack-bar/success-snack-bar.component';
import { ComponentType } from '@angular/cdk/overlay';
import { ErrorSnackBarComponent } from '../../shared/components/error-snack-bar/error-snack-bar.component';

export interface IMessage {
  title?: string;
  message: string;
}
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly config: MatSnackBarConfig;

  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly zone: NgZone,
    private readonly languageService: LanguageService,
  ) {
    this.config = new MatSnackBarConfig();
    this.config.panelClass = ['custom-snack-bar'];
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'right';
    this.config.duration = 7000;
  }

  error(translationKey?: string, addLeadingErrorPrefixForTranslations = true) {
    this.config.panelClass = ['custom-snack-bar', 'error'];
    const translationErrorPrefix = addLeadingErrorPrefixForTranslations ? 'errors.' : '';
    const message = this.generateSnackBarMessage(translationErrorPrefix + translationKey);
    this.showFromComponent({ message: message }, ErrorSnackBarComponent);
  }

  errorMessage(message: string) {
    this.config.panelClass = ['custom-snack-bar', 'error'];
    this.showFromComponent({ message: message }, ErrorSnackBarComponent);
  }

  success(translationKey: string, params?: object): void {
    this.config.panelClass = ['custom-snack-bar', 'success'];
    const message = this.generateSnackBarTitleAndMessage(translationKey, params);
    this.showFromComponent(message, SuccessSnackBarComponent);
  }

  warning(translationKey: string) {
    this.config.panelClass = ['custom-snack-bar', 'warning'];
    const message = this.generateSnackBarMessage(translationKey);
    this.show(message);
  }

  info(translationKey: string) {
    this.config.panelClass = ['custom-snack-bar', 'info'];
    const message = this.generateSnackBarMessage(translationKey);
    this.show(message);
  }

  private generateSnackBarMessage(translationKey: string, params?: object): string {
    return this.languageService.translateOnRuntime(translationKey, params);
  }

  private generateSnackBarTitleAndMessage(translationKey: string, params?: object): IMessage {
    return {
      title: this.languageService.translateOnRuntime(translationKey + '.title', params),
      message: this.languageService.translateOnRuntime(translationKey + '.message', params),
    };
  }

  private show(message: string, config?: MatSnackBarConfig) {
    config = config ?? this.config;
    this.zone.run(() => {
      this.snackbar.open(message, 'x', config);
    });
  }

  private showFromComponent(
    message: IMessage,
    component: ComponentType<ErrorSnackBarComponent | SuccessSnackBarComponent>,
    config?: MatSnackBarConfig,
  ) {
    config = {
      ...this.config,
      ...config,
      data: {
        ...message,
      },
    };
    this.zone.run(() => {
      this.snackbar.openFromComponent(component, config);
    });
  }
}
