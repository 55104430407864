import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [],
  templateUrl: './link.component.html',
})
export class LinkComponent {
  @Input({ required: true }) description: string;
  @Input() href?: string;
  @Input() clickAction?: () => void;

  onClick(event: Event) {
    if (!this.href) {
      event.preventDefault();
      this.clickAction?.();
    }
  }
}
