import { trigger, state, style, transition, animate } from '@angular/animations';

const setDefinition = (timings: string) => [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      timings,
      style({
        opacity: 1,
      }),
    ),
  ]),
];

export const easeInAnimationFast = trigger('easeInFast', setDefinition('0.2s ease-in'));

export const easeInAnimation = trigger('easeIn', setDefinition('0.4s ease-in'));
